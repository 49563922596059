<template>
  <div>
    <!-- <el-tabs v-model="active" @tab-click="handleClick" v-if="$route.meta.batchType!=8">
      <el-tab-pane label="按批次显示" name="first">
      </el-tab-pane>
      <el-tab-pane label="按客户显示" name="second">
      </el-tab-pane>
    </el-tabs> -->
    <BatchList :active="active"></BatchList>
  </div>

</template>
<script>

import BatchList from './components/batchList.vue'

export default {
  name: 'hnCodesIndex',
  components: {
    BatchList
  },
  data() {
    return {
      viewId: '',
      active: 'first',
    }

  },
  methods: {
    handleClick() {

    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";
</style>